
export const satsObj = [
    {
        "sportsType": "Cricket",
        "sportsCode": "SPOR05",
        "multiple": true,
        "items": [
            {

                "value": "",
                "name": "innings_type",
                "type": "dropDown",
                "label": "Innings Type",
                "options": [{
                    "id": "ODI",
                    "value": "ODI",
                },
                {
                    "id": "Test",
                    "value": "Test"
                },
                {
                    "id": "T20I",
                    "value": "T20I"
                },
                {
                    "id": "First Class",
                    "value": "First Class"
                },
                {
                    "id": "Domestic ODI",
                    "value": "Domestic ODI"
                },
                {
                    "id": "Domestic T20",
                    "value": "Domestic T20"
                },
                {
                    "id": "Sixers",
                    "value": "Sixers"
                }]

            },

            {
                "value": "",
                "name": "role_type",
                "type": "dropDown",
                "label": "Role Type",
                "options": [{
                    "id": "Batting",
                    "value": "Batting"
                },
                {
                    "id": "Bowling",
                    "value": "Bowling"
                },
                {
                    "id": "Fielding",
                    "value": "Fielding/wicket keeping"
                }]
            }


        ],
    },
    {
        "sportsType": "Tennis",
        "sportsCode": "SPOR08",
        "multiple": true,
        "items": [
            {
                "value": "",
                "name": "matches_type",
                "type": "dropDown",
                "label": "Matches Type",
                "options": [{
                    "id": "Singles",
                    "value": "Singles",
                },
                {
                    "id": "Doubles",
                    "value": "Doubles"
                },
                {
                    "id": "Mixed Doubles",
                    "value": "Mixed Doubles"
                }
                ]

            },

            {
                "value": "",
                "name": "surfaces_type",
                "type": "dropDown",
                "label": "Surfaces Type",
                "options": [{
                    "id": "All Surfaces",
                    "value": "All Surfaces",
                },
                {
                    "id": "Grass",
                    "value": "Grass"
                },
                {
                    "id": "Hard",
                    "value": "Hard"
                },
                {
                    "id": "Clay",
                    "value": "Clay",
                },
                {
                    "id": "Carpet",
                    "value": "Carpet"
                }
                ]
            },
            {
                "value": "",
                "name": "matches",
                "type": "text",
                "label": "Matches"
            },
            {
                "value": "",
                "name": "matches_won",
                "type": "text",
                "label": "Matches Won"
            },
            {
                "value": "",
                "name": "matches_lost",
                "type": "text",
                "label": "Matches Lost"
            },
            {
                "value": "",
                "name": "career_high_rank",
                "type": "text",
                "label": "Career High Rank"
            },
            {
                "value": "",
                "name": "no_of_titles",
                "type": "text",
                "label": "No. Of Titles"
            },
            {
                "value": "",
                "name": "aces",
                "type": "text",
                "label": "Aces"
            },
            {
                "value": "",
                "name": "double_faults",
                "type": "text",
                "label": "Double Faults"
            },
            {
                "value": "",
                "name": "first_serve",
                "type": "text",
                "label": "1st Serve"
            },
            {
                "value": "",
                "name": "first_serve_points_won",
                "type": "text",
                "label": "1st Serve Points Won"
            },
            {
                "value": "",
                "name": "second_serve_points_won",
                "type": "text",
                "label": "2nd Serve Points Won"
            },
            {
                "value": "",
                "name": "break_points_faced",
                "type": "text",
                "label": "Break Points Faced"
            },
            {
                "value": "",
                "name": "break_points_saved",
                "type": "text",
                "label": "Break Points Saved"
            },
            {
                "value": "",
                "name": "service_games_played",
                "type": "text",
                "label": "Service Games Played"
            },
            {
                "value": "",
                "name": "service_games_won",
                "type": "text",
                "label": "Service Games Won"
            },
            {
                "value": "",
                "name": "total_service_points_won",
                "type": "text",
                "label": "Total Service Points Won"
            },
            {
                "value": "",
                "name": "first_serve_return_points_won",
                "type": "text",
                "label": "1st Serve Return Points Won"
            },
            {
                "value": "",
                "name": "second_serve_return_points_won",
                "type": "text",
                "label": "2nd Serve Return Points Won"
            },
            {
                "value": "",
                "name": "career_high_rank",
                "type": "text",
                "label": "Career High Rank"
            },
            {
                "value": "",
                "name": "break_points_opportunities",
                "type": "text",
                "label": "Break Points Opportunities"
            },
            {
                "value": "",
                "name": "break_points_converted",
                "type": "text",
                "label": "Break Points Converted"
            },
            {
                "value": "",
                "name": "return_games_played ",
                "type": "text",
                "label": "Return Games Played "
            },
            {
                "value": "",
                "name": "return_games_won",
                "type": "text",
                "label": "Return Games Won"
            },
            {
                "value": "",
                "name": "return_points_won",
                "type": "text",
                "label": "Return Points Won"
            },
            {
                "value": "",
                "name": "total_points_won",
                "type": "text",
                "label": "Total Points Won"
            },
            {
                "value": "",
                "name": "coach",
                "type": "text",
                "label": "Coach"
            }


        ]
    },
    {
        "sportsType": "Foot-ball",
        "sportsCode": "SPOR07",
        "multiple": false,
        "items": [
            {
                "value": "",
                "name": "matches",
                "type": "text",
                "label": "Matches"
            },
            {
                "value": "",
                "name": "minutes",
                "type": "text",
                "label": "Minutes"
            },
            {
                "value": "",
                "name": "goals",
                "type": "text",
                "label": "Goals"
            },
            {
                "value": "",
                "name": "assists",
                "type": "text",
                "label": "Assists"
            },
            {
                "value": "",
                "name": "goal_conversion_rate",
                "type": "text",
                "label": "Goal Conversion Rate"
            },
            {
                "value": "",
                "name": "goals_game",
                "type": "text",
                "label": "Goals/Game"
            },
            {
                "value": "",
                "name": "shots",
                "type": "text",
                "label": "Shots"
            },
            {
                "value": "",
                "name": "crosses",
                "type": "text",
                "label": "Crosses"
            },
            {
                "value": "",
                "name": "passes",
                "type": "text",
                "label": "Passes"
            },
            {
                "value": "",
                "name": "touches",
                "type": "text",
                "label": "Touches"
            },
            {
                "value": "",
                "name": "average_passes_per_game",
                "type": "text",
                "label": "Average Passes Per Game"
            },
            {
                "value": "",
                "name": "takles",
                "type": "text",
                "label": "Tackles"
            },
            {
                "value": "",
                "name": "interceptions",
                "type": "text",
                "label": "Interceptions"
            },
            {
                "value": "",
                "name": "clearances",
                "type": "text",
                "label": "Clearances"
            },
            {
                "value": "",
                "name": "blocks",
                "type": "text",
                "label": "Blocks"
            },
            {
                "value": "",
                "name": "fouls",
                "type": "text",
                "label": "Fouls"
            },
            {
                "value": "",
                "name": "yellow_cards",
                "type": "text",
                "label": "Yellow Cards"
            },
            {
                "value": "",
                "name": "red_cards",
                "type": "text",
                "label": "Red Cards"
            }
        ],
    },
    {
        "sportsType": "TableTennis",
        "sportsCode": "SPOR06",
        "multiple": true,
        "items": [
            {
                "values": "",
                "name": "matches_type",
                "type": "dropDown",
                "label": "matches_type",
                "options": [{
                    "id": "Singles",
                    "value": "Singles",
                },
                {
                    "id": "Doubles",
                    "value": "Doubles"
                },

                {
                    "id": "Mixed Doubles",
                    "value": "Mixed Doubles"
                }
                ]

            },
            {
                "value": "",
                "name": "total_matches",
                "type": "text",
                "label": "Total Matches"
            },
            {
                "value": "",
                "name": "total_wins",
                "type": "text",
                "label": "Total Wins"
            },
            {
                "value": "",
                "name": "total_wins_percentage",
                "type": "text",
                "label": "Total Wins Percentage"
            },
            {
                "value": "",
                "name": "total_losses",
                "type": "text",
                "label": "Total Losses"
            },
            {
                "value": "",
                "name": "total_losses_percentage",
                "type": "text",
                "label": "Total Losses Percentage"
            },

        ]

    },


    {
        "sportsType": "Badminton",
        "sportsCode": "SPOR02",
        "multiple": true,
        "items": [
            {
                "values": "",
                "name": "matches_type",
                "type": "dropDown",
                "label": "matches_type",
                "options": [{
                    "id": "Singles",
                    "value": "Singles",
                },
                {
                    "id": "Doubles",
                    "value": "Doubles"
                },

                {
                    "id": "Mixed Doubles",
                    "value": "Mixed Doubles"
                }
                ]

            },
            {
                "value": "",
                "name": "total_matches",
                "type": "text",
                "label": "Total Matches"
            },
            {
                "value": "",
                "name": "total_wins",
                "type": "text",
                "label": "Total Wins"
            },
            {
                "value": "",
                "name": "total_wins_percentage",
                "type": "text",
                "label": "Total Wins Percentage"
            },
            {
                "value": "",
                "name": "total_losses",
                "type": "text",
                "label": "Total Losses"
            },
            {
                "value": "",
                "name": "total_losses_percentage",
                "type": "text",
                "label": "Total Losses Percentage"
            },

        ]

    },

    {
        "sportsType": "Squash",
        "sportsCode": "SPOR01",
        "multiple": true,
        "items": [
            {
                "values": "",
                "name": "matches_type",
                "type": "dropDown",
                "label": "matches_type",
                "options": [{
                    "id": "Singles",
                    "value": "Singles",
                },
                {
                    "id": "Doubles",
                    "value": "Doubles"
                },

                {
                    "id": "Mixed Doubles",
                    "value": "Mixed Doubles"
                }
                ]

            },
            {
                "value": "",
                "name": "total-matches",
                "type": "text",
                "label": "Total Matches"
            },
            {
                "value": "",
                "name": "total_wins",
                "type": "text",
                "label": "Total Wins"
            },
            {
                "value": "",
                "name": "total_wins_percentage",
                "type": "text",
                "label": "Total Wins Percentage"
            },
            {
                "value": "",
                "name": "total_losses",
                "type": "text",
                "label": "Total Losses"
            },
            {
                "value": "",
                "name": "total_losses_percentage",
                "type": "text",
                "label": "Total Losses Percentage"
            },

        ]

    },

    {
        "sportsType": "BasketBall",
        "sportsCode": "SPOR04",
        "multiple": false,
        "items": [
            {
                "value": "",
                "name": "points_per_game",
                "type": "text",
                "label": "Points Per Game(PPG)"
            },
            {
                "value": "",
                "name": "rebounds_per_game",
                "type": "text",
                "label": "Rebounds Per Game(RPG)"
            },
            {
                "value": "",
                "name": "assists_per_game",
                "type": "text",
                "label": "Assists Per Game(APG)"
            },
            {
                "value": "",
                "name": "steals_per_game",
                "type": "text",
                "label": "Steals Per Game(SPG)"
            },

            {
                "value": "",
                "name": "blocks_per_game",
                "type": "text",
                "label": "Blocks Per Game(BPG)"
            },
            {
                "value": "",
                "name": "turnovers_per_game",
                "type": "text",
                "label": "Turnovers Per Game(TO)"
            },
            {
                "value": "",
                "name": "player_efficiency_rating",
                "type": "text",
                "label": "Player Efficiency Rating(PER)"
            },
            {
                "value": "",
                "name": "win_shares",
                "type": "text",
                "label": "Win Shares(WS)"
            },
            {
                "value": "",
                "name": "field_goal_percentage",
                "type": "text",
                "label": "Field Goal Percentage (FG%)"
            },
            {
                "value": "",
                "name": "3_point_percentage",
                "type": "text",
                "label": "3 Point Percentage (3PT%)"
            },
            {
                "value": "",
                "name": "free_throw_percentage",
                "type": "text",
                "label": "Free Throw Percentage (FT%)"
            },

            {
                "value": "",
                "name": "effective_field_goal_percentage",
                "type": "text",
                "label": "Effective Field Goal Percentage (EFG%)"
            },

            {
                "value": "",
                "name": "true_shooting_percentage",
                "type": "text",
                "label": "True Shooting Percentage (TS%)"
            },


        ]
    },



    {
        "sportsType": "HandBall",
        "sportsCode": "SPOR03",
        "multiple": false,
        "items": [
            {
                "value": "",
                "name": "games_played",
                "type": "text",
                "label": "Games Played"
            },
            {
                "value": "",
                "name": "goals",
                "type": "text",
                "label": "Goals"
            },
            {
                "value": "",
                "name": "shots",
                "type": "text",
                "label": "Shots"
            },
            {
                "value": "",
                "name": "assists",
                "type": "text",
                "label": "Assists"
            },

            {
                "value": "",
                "name": "efficiency",
                "type": "text",
                "label": "Efficiency"
            },
            {
                "value": "",
                "name": "steals",
                "type": "text",
                "label": "Steals"
            },
            {
                "value": "",
                "name": "blocked_shots",
                "type": "text",
                "label": "Blocked Shots"
            },


        ]
    },



    {
        "sportsType": "VolleyBall",
        "sportsCode": "SPOR09",
        "multiple": false,
        "items": [
            {
                "value": "",
                "name": "games_played",
                "type": "text",
                "label": "Games Played (GP)"
            },
            {
                "value": "",
                "name": "assists",
                "type": "text",
                "label": "Assists (A)"
            },
            {
                "value": "",
                "name": "ball_handling_error",
                "type": "text",
                "label": "Ball Handling Error (BHE)"
            },
            {
                "value": "",
                "name": "blockassist",
                "type": "text",
                "label": "Block Assist (BA)"
            },

            {
                "value": "",
                "name": "block_solo",
                "type": "text",
                "label": "Block Solo (BS)"
            },
            {
                "value": "",
                "name": "dig",
                "type": "text",
                "label": "Dig"
            },
            {
                "value": "",
                "name": "error",
                "type": "text",
                "label": "Error (E)"
            },
            {
                "value": "",
                "name": "hitting_percentage",
                "type": "text",
                "label": "Hitting Percentage (PCT)"
            },
            {
                "value": "",
                "name": "kills",
                "type": "text",
                "label": "Kills (K)"
            },
            {
                "value": "",
                "name": "reception_error",
                "type": "text",
                "label": "Reception Error (RE)"
            },
            {
                "value": "",
                "name": "service_ace",
                "type": "text",
                "label": "Service Ace (SA)"
            },

            {
                "value": "",
                "name": "service_error",
                "type": "text",
                "label": "Service Error (SE)"
            },

            {
                "value": "",
                "name": "total_attempts",
                "type": "text",
                "label": "Total Attempts (TA)"
            },

            {
                "value": "",
                "name": "zero_attacks",
                "type": "text",
                "label": "Zero Attacks"
            },


        ]
    },



    {
        "sportsType": "Hockey",
        "sportsCode": "SPOR10",
        "multiple": false,
        "items": [
            {
                "value": "",
                "name": "games_played",
                "type": "text",
                "label": "Games Played"
            },
            {
                "value": "",
                "name": "face_off_percentage",
                "type": "text",
                "label": "Face-Off Percentage (FO%)"
            },
            {
                "value": "",
                "name": "assists",
                "type": "text",
                "label": "Assists"
            },
            {
                "value": "",
                "name": "goals",
                "type": "text",
                "label": "Goals"
            },

            {
                "value": "",
                "name": "points",
                "type": "text",
                "label": "Points"
            },
            {
                "value": "",
                "name": "shooting_percentage",
                "type": "text",
                "label": "Shooting Percentage (ST%)"
            },
            {
                "value": "",
                "name": "penalty_minutes",
                "type": "text",
                "label": "Penalty Minutes (PIM)"
            },
            {
                "value": "",
                "name": "rating",
                "type": "text",
                "label": "+/- (Plus – Minus Rating)"
            },

        ]
    },




    {
        "sportsType": "SoftBall",
        "sportsCode": "SPOR12",
        "multiple": false,
        "items": [
            {

                "value": "",
                "name": "softBallStatic",
                "type": "dropDown",
                "label": "type",
                "options": [{
                    "id": "batting_statistics",
                    "value": "Batting Statistics",
                },
                {
                    "id": "pitching_statistics",
                    "value": "Pitching Statistics"
                }]

            },


        ]


    },


    {
        "sportsType": "BaseBall",
        "sportsCode": "SPOR13",
        "multiple": false,
        "items": [
            {

                "value": "",
                "name": "baseBallStatic",
                "type": "dropDown",
                "label": "type",
                "options": [{
                    "id": "batting_statistics",
                    "value": "Batting Statistics",
                },
                {
                    "id": "pitching_statistics",
                    "value": "Pitching Statistics"
                }]

            },

        ]

    },



];

export const softBall = {
    "batting_statistics": [

        {
            "value": "",
            "name": "batting_average",
            "type": "text",
            "label": "Batting Average (BA)"
        },
        {
            "value": "",
            "name": "home_runs",
            "type": "text",
            "label": "Home Runs (HR)"
        },
        {
            "value": "",
            "name": "runs_batted_in",
            "type": "text",
            "label": "Runs Batted in (RBI)"
        },
        {
            "value": "",
            "name": "runs_scored",
            "type": "text",
            "label": "Runs Scored"
        },
        {
            "value": "",
            "name": "stolen_bases",
            "type": "text",
            "label": "Stolen Bases"
        },


    ],
    "pitching_statistics": [
        {
            "value": "",
            "name": "Wins",
            "type": "text",
            "label": "Wins"
        },
        {
            "value": "",
            "name": "earned_run_average",
            "type": "text",
            "label": "Earned Run Average (ERA)"
        },
        {
            "value": "",
            "name": "walks_and_hits_per_innings_pitched",
            "type": "text",
            "label": "Walks and Hits Per Innings Pitched"
        },

        {
            "value": "",
            "name": "Strike-outs",
            "type": "text",
            "label": "Strike-outs"
        },

        {
            "value": "",
            "name": "saves",
            "type": "text",
            "label": "Saves"
        },

        {
            "value": "",
            "name": "srrors",
            "type": "text",
            "label": "Errors"
        },
    ]
}

export const cricketFields = {
    "Batting": [
        {
            "value": "",
            "name": "batting_matches",
            "type": "text",
            "label": "Matches"
        },
        {
            "value": "",
            "name": "batting_innings",
            "type": "text",
            "label": "Innings"
        },
        {
            "value": "",
            "name": "not_outs",
            "type": "text",
            "label": "Not Outs"
        },
        {
            "value": "",
            "name": "runs",
            "type": "text",
            "label": "Runs"
        },
        {
            "value": "",
            "name": "batting_average",
            "type": "text",
            "label": "Average"
        },
        {
            "value": "",
            "name": "batting_strike_rate",
            "type": "text",
            "label": "Strike Rate"
        },
        {
            "value": "",
            "name": "fifties",
            "type": "text",
            "label": "50s"
        },
        {
            "value": "",
            "name": "hundreds",
            "type": "text",
            "label": "100s"
        },
        {
            "value": "",
            "name": "highest_run",
            "type": "text",
            "label": "Highest Run"
        },
        {
            "value": "",
            "name": "fours",
            "type": "text",
            "label": "4s"
        },
        {
            "value": "",
            "name": "sixers",
            "type": "text",
            "label": "6s"
        },
        {
            "value": "",
            "name": "duks",
            "type": "text",
            "label": "Ducks"
        },
        {
            "value": "",
            "name": "won",
            "type": "text",
            "label": "Won"
        },
        {
            "value": "",
            "name": "loss",
            "type": "text",
            "label": "Loss"
        }
    ],
    "Bowling": [
        {
            "value": "",
            "name": "bowling_matches",
            "type": "text",
            "label": "Matches"
        },
        {
            "value": "",
            "name": "bowling_innings",
            "type": "text",
            "label": "Innings"
        },
        {
            "value": "",
            "name": "over",
            "type": "text",
            "label": "Over"
        },
        {
            "value": "",
            "name": "maidens",
            "type": "text",
            "label": "Maidens"
        },
        {
            "value": "",
            "name": "wickets",
            "type": "text",
            "label": "Wickets"
        },
        {
            "value": "",
            "name": "run",
            "type": "text",
            "label": "Run"
        },
        {
            "value": "",
            "name": "best_bowling",
            "type": "text",
            "label": "Best Bowling"
        },
        {
            "value": "",
            "name": "three_wickets",
            "type": "text",
            "label": "3 Wickets"
        },
        {
            "value": "",
            "name": "five_wickets",
            "type": "text",
            "label": "5 Wickets"
        },
        {
            "value": "",
            "name": "ten_wickets",
            "type": "text",
            "label": "10 Wickets"
        },
        {
            "value": "",
            "name": "economy",
            "type": "text",
            "label": "Economy"
        },
        {
            "value": "",
            "name": "bowling_strike_rate",
            "type": "text",
            "label": "Strike Rate"
        },
        {
            "value": "",
            "name": "bowling_average",
            "type": "text",
            "label": "Average"
        }
    ],
    "Fielding": [
        {
            "value": "",
            "name": "fielding_matches",
            "type": "text",
            "label": "Matches"
        },
        {
            "value": "",
            "name": "catches",
            "type": "text",
            "label": "Catches"
        },
        {
            "value": "",
            "name": "caught_behind",
            "type": "text",
            "label": "Caught-Behind"
        },
        {
            "value": "",
            "name": "run_outs",
            "type": "text",
            "label": "Run-Outs"
        },
        {
            "value": "",
            "name": "stumpings",
            "type": "text",
            "label": "Stumpings"
        },
        {
            "value": "",
            "name": "assisted_run_outs",
            "type": "text",
            "label": "Assisted Run-Outs"
        }
    ]
}

// export const tennisFields = [
//     {
//         "value": "",
//         "name": "matches",
//         "type": "text",
//         "label": "Matches"
//     },
//     {
//         "value": "",
//         "name": "matches_won",
//         "type": "text",
//         "label": "Matches Won"
//     },
//     {
//         "value": "",
//         "name": "matches_lost",
//         "type": "text",
//         "label": "Matches Lost"
//     },
//     {
//         "value": "",
//         "name": "career_high_rank",
//         "type": "text",
//         "label": "Career High Rank"
//     },
//     {
//         "value": "",
//         "name": "no_of_titles",
//         "type": "text",
//         "label": "No. Of Titles"
//     },
//     {
//         "value": "",
//         "name": "aces",
//         "type": "text",
//         "label": "Aces"
//     },
//     {
//         "value": "",
//         "name": "double_faults",
//         "type": "text",
//         "label": "Double Faults"
//     },
//     {
//         "value": "",
//         "name": "first_serve",
//         "type": "text",
//         "label": "1st Serve"
//     },
//     {
//         "value": "",
//         "name": "first_serve_points_won",
//         "type": "text",
//         "label": "1st Serve Points Won"
//     },
//     {
//         "value": "",
//         "name": "second_serve_points_won",
//         "type": "text",
//         "label": "2nd Serve Points Won"
//     },
//     {
//         "value": "",
//         "name": "break_points_faced",
//         "type": "text",
//         "label": "Break Points Faced"
//     },
//     {
//         "value": "",
//         "name": "break_points_saved",
//         "type": "text",
//         "label": "Break Points Saved"
//     },
//     {
//         "value": "",
//         "name": "service_games_played",
//         "type": "text",
//         "label": "Service Games Played"
//     },
//     {
//         "value": "",
//         "name": "service_games_won",
//         "type": "text",
//         "label": "Service Games Won"
//     },
//     {
//         "value": "",
//         "name": "total_service_points_won",
//         "type": "text",
//         "label": "Total Service Points Won"
//     },
//     {
//         "value": "",
//         "name": "first_serve_return_points_won",
//         "type": "text",
//         "label": "1st Serve Return Points Won"
//     },
//     {
//         "value": "",
//         "name": "second_serve_return_points_won",
//         "type": "text",
//         "label": "2nd Serve Return Points Won"
//     },
//     {
//         "value": "",
//         "name": "career_high_rank",
//         "type": "text",
//         "label": "Career High Rank"
//     },
//     {
//         "value": "",
//         "name": "break_points_opportunities",
//         "type": "text",
//         "label": "Break Points Opportunities"
//     },
//     {
//         "value": "",
//         "name": "break_points_converted",
//         "type": "text",
//         "label": "Break Points Converted"
//     },
//     {
//         "value": "",
//         "name": "return_games_played ",
//         "type": "text",
//         "label": "Return Games Played "
//     },
//     {
//         "value": "",
//         "name": "return_games_won",
//         "type": "text",
//         "label": "Return Games Won"
//     },
//     {
//         "value": "",
//         "name": "return_points_won",
//         "type": "text",
//         "label": "Return Points Won"
//     },
//     {
//         "value": "",
//         "name": "total_points_won",
//         "type": "text",
//         "label": "Total Points Won"
//     },
//     {
//         "value": "",
//         "name": "coach",
//         "type": "text",
//         "label": "Coach"
//     },
// ]

// export const footBallFields = [
//     {
//         "value": "",
//         "name": "matches",
//         "type": "text",
//         "label": "Matches"
//     },
//     {
//         "value": "",
//         "name": "minutes",
//         "type": "text",
//         "label": "Minutes"
//     },
//     {
//         "value": "",
//         "name": "goals",
//         "type": "text",
//         "label": "Goals"
//     },
//     {
//         "value": "",
//         "name": "assists",
//         "type": "text",
//         "label": "Assists"
//     },
//     {
//         "value": "",
//         "name": "goal_conversion_rate",
//         "type": "text",
//         "label": "Goal Conversion Rate"
//     },
//     {
//         "value": "",
//         "name": "goals_game",
//         "type": "text",
//         "label": "Goals/Game"
//     },
//     {
//         "value": "",
//         "name": "shots",
//         "type": "text",
//         "label": "Shots"
//     },
//     {
//         "value": "",
//         "name": "crosses",
//         "type": "text",
//         "label": "Crosses"
//     },
//     {
//         "value": "",
//         "name": "passes",
//         "type": "text",
//         "label": "Passes"
//     },
//     {
//         "value": "",
//         "name": "touches",
//         "type": "text",
//         "label": "Touches"
//     },
//     {
//         "value": "",
//         "name": "average_passes_per_game",
//         "type": "text",
//         "label": "Average Passes Per Game"
//     },
//     {
//         "value": "",
//         "name": "takles",
//         "type": "text",
//         "label": "Tackles"
//     },
//     {
//         "value": "",
//         "name": "interceptions",
//         "type": "text",
//         "label": "Interceptions"
//     },
//     {
//         "value": "",
//         "name": "clearances",
//         "type": "text",
//         "label": "Clearances"
//     },
//     {
//         "value": "",
//         "name": "blocks",
//         "type": "text",
//         "label": "Blocks"
//     },
//     {
//         "value": "",
//         "name": "fouls",
//         "type": "text",
//         "label": "Fouls"
//     },
//     {
//         "value": "",
//         "name": "yellow_cards",
//         "type": "text",
//         "label": "Yellow Cards"
//     },
//     {
//         "value": "",
//         "name": "red_cards",
//         "type": "text",
//         "label": "Red Cards"
//     }
// ]




export const statsFunctn = async (code) => {
    let data = null;
    for await (const obj of satsObj) {
        if (obj?.sportsCode?.toUpperCase() === code.toUpperCase()) {
            data = obj;
            break;
        }
    }
    return data;
}


